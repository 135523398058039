import React from "react";
import FooterLogo from "../../../Assets/logo.png";
import { useTranslation } from "react-i18next";
import { Loading } from "../../Loading/View/Loading.view";
import { Img } from "xa-image";
import "./Style/Footer.css";

export interface IFooterProps {}

const Footer: React.FC<IFooterProps> = (props) => {
    const { t } = useTranslation();

    return (
        <div className={"page-footer"}>
            <div className="page-footer__container">
                <div className="page-footer__logo">
                    <Img
                        loader={<Loading />}
                        className="page-footer__logo--image"
                        src={FooterLogo}
                    />
                </div>
                <div className="page-footer__data">
                    <div className="page-footer__data--title">{t("openHoursText")}</div>
                    <div className="page-footer__data--detail-text">
                        <p className="text">{t("openHoursDate1")}</p>
                        <p className="text">{t("openHoursTime1")}</p>
                    </div>
                    <div className="page-footer__data--detail-text">
                        <p className="text">{t("openHoursDate2")}</p>
                        <p className="text">{t("openHoursTime2")}</p>
                    </div>
                </div>
                <div className="page-footer__data">
                    <div className="page-footer__data--title">{t("availabilityTitle")}</div>
                    <div className="page-footer__data--detail-text">
                        <p className="text">{t("availabilityStreet")}</p>
                        <p className="text">{t("availabilityPhone")}</p>
                    </div>
                </div>
                <div className="page-footer__data">
                    <div className="page-footer__data--title">{t("followUsTitle")}</div>
                    <div className="page-footer__data--detail-text">
                        <a
                            href={"https://www.facebook.com/FitandFineFoods"}
                            target="_blank"
                            className="link"
                            rel="noreferrer noopener"
                        >
                            <span className="flaticon-facebook-logo" />
                        </a>
                        <a
                            href="https://www.instagram.com/fitandfinefood"
                            target="_blank"
                            className="link"
                            rel="noreferrer noopener"
                        >
                            <span className="flaticon-instagram" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="page-footer__copyright">
                <span className="flaticon-copyright footer-icon"></span>{" "}
                {new Date().getFullYear() + " " + t("footerText")}
            </div>
        </div>
    );
};

export default Footer;
