import HU from "./HU.json";
import EN from "./EN.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export default function LangConfig(defaultLanguage: string = "hu") {
    i18n.use(initReactI18next).init({
        resources: {
            //Keys MUST match the registerLocale keys above.
            hu: {
                translation: HU
            },
            en: {
                translation: EN
            }
        },
        lng: defaultLanguage,
        fallbackLng: "hu",
        interpolation: {
            escapeValue: true
        }
    });
}
