import React from "react";
import "./Style/Loading.css";

export interface ILoadingProps {
    isInline?: boolean;
    style?: React.CSSProperties;
}

export const Loading: React.FC<ILoadingProps> = (props) => {
    let classes: string[] = ["loading-container"];
    if (props.isInline) classes.push("inline-loading-container");
    return (
        <div className={classes.join(" ")} style={props.style}>
            <div className="loading"></div>
        </div>
    );
};
