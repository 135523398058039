import React from "react";
import { DayModel } from "../Model/WeeklyMenu.model";
import { useTranslation } from "react-i18next";
import { ILoading, IError } from "xa-generics";
import "./Style/WeeklyMenu.css";

export interface IWeeklyMenuViewProps {
    dayMenu: DayModel[];
    menuPrice: string;
    loading: ILoading;
    error: IError;
}

const WeeklyMenuView: React.FC<IWeeklyMenuViewProps> = (props) => {
    const { t } = useTranslation();

    let nodes: JSX.Element[] = [];

    for (let data of props.dayMenu) {
        nodes.push(
            <div key={`${data.day}-day-key`} className="menu-list">
                <div className="menu-list__date">
                    <div className="menu-list__date--day">{t(data.day)}</div>
                    <div className="menu-list__date--date">{data.date}</div>
                </div>
                <div className="menu-list__menu">
                    <div className="menu-list__menu--fixed">
                        <div className="option">{data.soup}</div>
                        {data.mainCourse && (
                            <div className={data.mainCourseB ? "option option--ab" : "option"}>
                                {data.mainCourseB && (
                                    <div className="optiontype optiontype--A">A</div>
                                )}
                                {data.mainCourse}
                            </div>
                        )}
                        {data.mainCourseB && (
                            <div className="option option--ab">
                                <div className="optiontype optiontype--B">B</div>
                                {data.mainCourseB}
                            </div>
                        )}
                        {data.dessert && <div className="option">{data.dessert}</div>}
                    </div>
                    <div className="menu-list__menu--optional">
                        {(data.alternateDishName ||
                            data.alternateDishPrice ||
                            data.alternateDish1 ||
                            data.alternateDish2 ||
                            data.alternateDish3) && (
                            <div className="special-offer">
                                <h3 className="special-offer--title">{t("specialOffer")}</h3>
                                {
                                    <span className="special-offer--description">
                                        {data.alternateDishName}{" "}
                                        {data.alternateDishName && data.alternateDishPrice && "-"}{" "}
                                        {data.alternateDishPrice
                                            ? data.alternateDishPrice + " Ft"
                                            : null}
                                    </span>
                                }
                            </div>
                        )}
                        {data.alternateDish1 && <div className="option">{data.alternateDish1}</div>}
                        {data.alternateDish2 && <div className="option">{data.alternateDish2}</div>}
                        {data.alternateDish3 && <div className="option">{data.alternateDish3}</div>}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="weekly-menu" id="MENU">
            <div className="weekly-menu__header">
                <h2 className="weekly-menu__header--title">{t("weeklyTitle")}</h2>
                <div className="weekly-menu__header--price">
                    {t("weeklyPrice")} {props.menuPrice}
                </div>
            </div>
            <div className="weekly-menu__list">
                {props.loading && (
                    <div className="loading-container">
                        <div className="loading"></div>
                    </div>
                )}
                {props.error ? t("failLoad") : nodes}
            </div>
        </div>
    );
};

export default WeeklyMenuView;
