import React, { useState, useEffect } from "react";
import SmallBannerImage from "../../../Assets/banner_small.jpg";
import BannerImage from "../../../Assets/banner.jpg";
import BannerImage1 from "../../../Assets/food3.png";
import BannerImage2 from "../../../Assets/food5.png";
import { Loading } from "../../Loading/View/Loading.view";
import { Img } from "xa-image";
import "./Style/Banner.css";

export interface IBannerProps {
    isNoContent?: boolean;
}

const Banner: React.FC<IBannerProps> = (props) => {
    const [size, setsize] = useState<number>(window.innerWidth);

    const changeSize = (): void => {
        setsize(window.innerWidth);
    };

    const trackScreenSize = (): (() => void) => {
        window.addEventListener("resize", changeSize, { passive: true });
        return () => {
            window.removeEventListener("resize", changeSize);
        };
    };
    useEffect(trackScreenSize, []);

    //Base64 encoded img source
    const imgSrc: string = size < 991 ? SmallBannerImage : BannerImage;

    return (
        <>
            <div className="banner">
                <Img
                    loader={<Loading style={{ padding: "2rem 1rem" }} />}
                    src={imgSrc}
                    className="banner__image"
                    alt="Banner"
                />
                {props.isNoContent ? null : (
                    <div className="banner__content">
                        <div className="banner__content--image">
                            <Img
                                src={BannerImage1}
                                alt="Food 1"
                                className="banner__content--image--tag"
                            />
                        </div>
                        <div className="banner__content--image">
                            <Img
                                src={BannerImage2}
                                alt="Food 2"
                                className="banner__content--image--tag"
                            />
                        </div>
                    </div>
                )}
            </div>
            {props.children}
        </>
    );
};

export default Banner;
