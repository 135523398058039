import { ItemModel } from "./Item.model";
import { IDynamicObject, setString, setBoolean } from "xa-generics";
import { SubItemModel } from "./SubItem.model";

export class DrinkMenuModel {
    constructor(data?: IDynamicObject<any>) {
        if (!data) return;
        this.has_subcategory = setBoolean(data.has_subcategory);
        this.category = setString(data.category);

        if (data.units instanceof Array) {
            this.units = data.units.map((unit: string) => setString(unit));
        }

        if (data.items instanceof Array) {
            for (let item of data.items) {
                this.items.push(
                    data.has_subcategory ? new SubItemModel(item) : new ItemModel(item)
                );
            }
        }
    }

    items: (ItemModel | SubItemModel)[] = [];
    has_subcategory: boolean = false;
    category: string = "";
    units: string[] = [];
}
