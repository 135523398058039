import React from "react";
import { IError, ILoading } from "xa-generics";
import { useTranslation } from "react-i18next";
import { MenuModel } from "../Model/Menu.model";
import { Loading } from "../../Loading/View/Loading.view";
import Footer from "../../Footer/View/Footer.view";
// import Banner from "../../Banner/View/Banner.view";
import "./Style/DrinkMenuView.css";
import "./Style/MenuView.css";

export interface IMenuViewProps {
    menu: MenuModel[];
    loading: ILoading;
    error: IError;
}

const MenuView: React.FC<IMenuViewProps> = (props) => {
    const { t } = useTranslation();
    return (
        <>
            {/* <Banner isNoContent /> */}
            <div className="drink-menu-wrapper">
                <div className="menu-wrapper">
                    <h1 className="main-title">{t("Étlap")}</h1>
                    <div className="menu-content">
                        {props.loading ? (
                            <Loading />
                        ) : (
                            props.menu.map((menu, index) => {
                                return (
                                    <React.Fragment key={`menu-category-${index}`}>
                                        <h2 className="sub-title">{menu.category}</h2>
                                        {menu.items.map((menuItem, index) => {
                                            if ("subcategory" in menuItem) {
                                                return (
                                                    <React.Fragment key={`menu-element-${index}`}>
                                                        <h3 className="sub-title-2">
                                                            {menuItem.subcategory}
                                                        </h3>
                                                        {menuItem.items.map((item, index) => {
                                                            return (
                                                                <div
                                                                    className="menu-item"
                                                                    key={`menu-item-${index}`}
                                                                >
                                                                    <div className="menu-item__left">
                                                                        {item.name ||
                                                                            t("missingFoodName")}
                                                                        <p className="text">
                                                                            {item.description}
                                                                        </p>
                                                                    </div>
                                                                    <div className="menu-item__right">
                                                                        {item.price ||
                                                                            t("missingFoodPrice")}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </React.Fragment>
                                                );
                                            } else
                                                return (
                                                    <div
                                                        className="menu-item"
                                                        key={`menu-element-${index}`}
                                                    >
                                                        <div className="menu-item__left">
                                                            {menuItem.name || t("missingFoodName")}
                                                            <p className="text">
                                                                {menuItem.description}
                                                            </p>{" "}
                                                        </div>
                                                        <div className="menu-item__right">
                                                            {menuItem.price ||
                                                                t("missingFoodPrice")}
                                                        </div>
                                                    </div>
                                                );
                                        })}
                                    </React.Fragment>
                                );
                            })
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default MenuView;
