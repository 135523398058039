import React, { useState, useEffect } from "react";
import { ILoading, IError } from "xa-generics";
import { WeeklyMenuDAO } from "../DAO/WeeklyMenu.dao";
import { DayModel } from "../Model/WeeklyMenu.model";
import WeeklyMenuView from "../View/WeeklyMenu.view";

export interface IWeeklyMenuProps {}

const WeeklyMenu: React.FC<IWeeklyMenuProps> = (props) => {
    const [dayMenu, setDayMenu] = useState<DayModel[]>([]);
    const [menuPrice, setMenuPrice] = useState<string>("");
    const [loading, setLoading] = useState<ILoading>(true);
    const [error, setError] = useState<IError>(null);

    const loadMenu = (): void => {
        setLoading(
            WeeklyMenuDAO.getWeeklyMenu()
                .then((data) => {
                    setMenuPrice(data.menuPrice);
                    setDayMenu(data.weekDays);
                })
                .catch((error: IError) => {
                    setError(error);
                })
                .finally(() => setLoading(false))
        );
    };

    useEffect(loadMenu, []);

    return (
        <WeeklyMenuView dayMenu={dayMenu} menuPrice={menuPrice} loading={loading} error={error} />
    );
};

export default WeeklyMenu;
