import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";
// import { axios } from "xa-generics";
import { Img } from "xa-image";
// import Cookie from "js-cookie";
import Logo from "../../../Assets/logo.png";
import "./Style/Navigation.css";

export interface INavigationBarProps extends RouteComponentProps {}

const NavigationBar: React.FC<INavigationBarProps> = (props) => {
    const [isOpen, setisOpen] = useState<boolean>(false);
    const { t /* i18n */ } = useTranslation();

    const classes: string[] = ["navbar-container"];

    // const changeLang = (newLang: string): void => {
    //     if (isOpen) setisOpen(false);
    //     i18n.changeLanguage(newLang);
    //     Cookie.set("lang", newLang, {
    //         expires: 6000,
    //         secure: process.env.NODE_ENV === "production" ? true : false
    //     });
    //     axios.setLanguage(newLang);
    // };

    const handleClick = (): void => {
        if (isOpen) setisOpen(false);
    };

    if (isOpen) classes.push("navbar-container-opened");

    return (
        <div className={classes.join(" ")}>
            <div className="navbar">
                <Img
                    src={Logo}
                    alt={"TITLE"}
                    onClick={() => {
                        props.history.push("/");
                        if (isOpen) setisOpen(false);
                    }}
                    className="navbar__logo"
                />
                <HashLink to="/#MENU" className="navbar__link" smooth onClick={handleClick}>
                    <div className="text-container">{t("weeklyTitle")}</div>
                </HashLink>
                <HashLink to="/#ROLUNK" smooth className="navbar__link" onClick={handleClick}>
                    <div className="text-container">{t("menuAboutUs")}</div>
                </HashLink>
                <HashLink
                    to="/#ASZTALFOGLALAS"
                    smooth
                    className="navbar__link"
                    onClick={handleClick}
                >
                    <div className="text-container">{t("reservationTitle")}</div>
                </HashLink>

                <NavLink to="/etlap" className="navbar__link" onClick={handleClick}>
                    <div className="text-container">{t("menuMenu")}</div>
                </NavLink>

                <NavLink to="/itallap" className="navbar__link" onClick={handleClick}>
                    <div className="text-container">{t("menuDrinks")}</div>
                </NavLink>

                <div className="navbar__language">
                    {/* <div className="navbar__language--text">
                        <span
                            className="navbar__language--selector"
                            onClick={() => changeLang("hu")}
                        >
                            HU
                        </span>{" "}
                        /
                        <span
                            className="navbar__language--selector"
                            onClick={() => changeLang("en")}
                        >
                            EN
                        </span>
                    </div> */}
                    <div className="navbar__toggler">
                        <span
                            className="flaticon-menu navbar__toggler--icon"
                            onClick={() => setisOpen(!isOpen)}
                        ></span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavigationBar;
