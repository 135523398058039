import { IDynamicObject, setString } from "xa-generics";
import { SubItemModel } from "./SubItem.model";
import { ItemModel } from "./Item.model";

export class MenuModel {
    constructor(data?: IDynamicObject<any>) {
        if (!data) return;

        this.category = setString(data.category);
        if (data.items instanceof Array) {
            for (let item of data.items) {
                this.items.push(item.subcategory ? new SubItemModel(item) : new ItemModel(item));
            }
        }
    }

    category: string = "";
    items: (ItemModel | SubItemModel)[] = [];
}
