import React from "react";
import Image2 from "../../../Assets/mcgregor.jpg";
import Image3 from "../../../Assets/football.jpg";
import mozaikImage01 from "../../../Assets/food1.png";
import mozaikImage02 from "../../../Assets/food2.png";
import mozaikImage03 from "../../../Assets/food3.png";
import mozaikImage04 from "../../../Assets/food4.png";
import mozaikImage05 from "../../../Assets/food5.png";
import { useTranslation } from "react-i18next";
import { Loading } from "../../Loading/View/Loading.view";
import { Img } from "xa-image";
import "./Style/AboutUs.css";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export interface IAboutUsProps {}

const AboutUs: React.FC<IAboutUsProps> = (props) => {
    const { t } = useTranslation();

    const mozaikImages = [
        mozaikImage01,
        mozaikImage02,
        mozaikImage03,
        mozaikImage04,
        mozaikImage05
    ];

    return (
        <div className="about-us">
            <div className="about-us__texts" id="ROLUNK">
                <div className="container">
                    <div className="about-us__button-container">
                        <NavLink to="/etlap">
                            <button className="about-us__button">Étlap</button>
                        </NavLink>
                        <HashLink to="/#MENU" smooth>
                            <button className="about-us__button">Heti Menu</button>
                        </HashLink>
                    </div>
                    <div className="about-us__texts--title">{t("aboutUsTitle")}</div>
                    <div className="about-us__texts--description">
                        <p className="desc">{t("aboutUsText1")}</p>
                        <p className="desc">{t("aboutUsText2")}</p>
                        <p className="desc">{t("aboutUsText3")}</p>
                        <p className="desc">{t("aboutUsText4")}</p>
                        <p className="desc">{t("aboutUsText5")}</p>
                    </div>
                </div>
            </div>
            <div className="about-us__kitchen">
                <div className="container">
                    <div className="about-us__kitchen--column full-width">
                        <h2 className="about-us__kitchen--title">{t("kitchenTitle")}</h2>
                        <p className="about-us__kitchen--text">
                            <span className="text">{t("kitchenText1")}</span>
                            <span className="text">{t("kitchenText2")}</span>
                            <span className="text">{t("kitchenText3")}</span>
                        </p>
                    </div>
                    <div className="about-us__kitchen--column">
                        <Img src={Image2} className="about-us__kitchen--image" />
                    </div>
                    <div className="about-us__kitchen--column">
                        <Img src={Image3} className="about-us__kitchen--image" />
                    </div>
                </div>
            </div>
            <div className="about-us__mozaic">
                {mozaikImages.map((imageURL: string, index: number) => (
                    <Img
                        loader={<Loading isInline style={{ width: "20%" }} />}
                        src={imageURL}
                        className="about-us__mozaic--image"
                        key={`${index}-about-img`}
                    />
                ))}
            </div>
        </div>
    );
};

export default AboutUs;
