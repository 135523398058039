import React from "react";
import { IReservationForm } from "../Interface/IReservationForm.interface";
import { useTranslation } from "react-i18next";
import { IInputChange } from "../Interface/IInputChange.interface";
import { formatTime } from "xa-generics";
import "./Style/Reservation.css";

export interface IReservationViewProps {
    onSubmit: (event: React.FormEvent) => void;
    onChange: (args: IInputChange) => void;
    form: IReservationForm;
}

const ReservationView: React.FC<IReservationViewProps> = (props) => {
    const { t } = useTranslation();
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        props.onChange({
            fieldName: e.currentTarget.id,
            value: e.currentTarget.value
        });
    };

    let date = new Date();
    let dateText = `${date.getFullYear()}.${formatTime(date.getMonth())}.${formatTime(
            date.getDate()
        )}`,
        timeText = `${formatTime(date.getHours())}:${formatTime(date.getMinutes())}`;

    return (
        <div className="reservation" id="ASZTALFOGLALAS">
            <div className="reservation-gradient"></div>
            <div className="reservation__form-container">
                <div className="reservation__header">
                    <div className="reservation__header--title">{t("reservationTitle")}</div>
                    <div className="reservation__header--details">{t("reservationText")}</div>
                </div>
                <form onSubmit={props.onSubmit} className="reservation__form">
                    <div className="input-wrapper">
                        <label htmlFor="name" className="input-label">
                            {t("question1")}
                        </label>
                        <input
                            onChange={onChange}
                            type="text"
                            className="input-element"
                            {...props.form.name}
                        />
                    </div>
                    <div className="input-wrapper">
                        <label htmlFor="tel" className="input-label">
                            {t("question2")}
                        </label>
                        <input
                            onChange={onChange}
                            type="tel"
                            className="input-element"
                            {...props.form.tel}
                        />
                    </div>
                    <div className="input-wrapper">
                        <label htmlFor="email" className="input-label">
                            {t("question3")}
                        </label>
                        <input
                            onChange={onChange}
                            type="email"
                            className="input-element"
                            {...props.form.email}
                        />
                    </div>

                    <div className="input-wrapper input-wrapper-half">
                        <div className="input-label">{t("question4")}</div>

                        <input
                            onChange={onChange}
                            type="text"
                            className="input-element"
                            {...props.form.date}
                            placeholder={dateText}
                        />
                        <input
                            onChange={onChange}
                            type="text"
                            className="input-element"
                            {...props.form.time}
                            placeholder={timeText}
                        />
                    </div>

                    <div className="input-wrapper">
                        <label htmlFor="personCount" className="input-label">
                            {t("question5")}
                        </label>
                        <input
                            onChange={onChange}
                            type="text"
                            className="input-element"
                            {...props.form.personCount}
                        />
                    </div>
                    <div className="input-wrapper">
                        <label htmlFor="comment" className="input-label">
                            {t("question6")}
                        </label>
                        <input
                            onChange={onChange}
                            type="text"
                            placeholder={t("question6")}
                            className="input-element"
                            {...props.form.comment}
                        />
                    </div>

                    <div className="submit-section">
                        <button className="form-submit-button" type="submit">
                            {t("resButton")}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ReservationView;
