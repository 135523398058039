import { axios } from "xa-generics";
import { AxiosPromise, AxiosResponse } from "axios";

export abstract class ReservationDAO {
    private static readonly base: string = "/api/reserve";

    public static async reserveTable(data: string): Promise<void> {
        const request: AxiosPromise<void> = axios.getInstance().post(`${this.base}`, data);
        const response: AxiosResponse<void> = await request;
        return response.data;
    }
}
