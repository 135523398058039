import React from "react";
import { ILoading, IError } from "xa-generics";
import { DrinkMenuModel } from "../Model/DrinkMenu.model";
import { useTranslation } from "react-i18next";
import { Loading } from "../../Loading/View/Loading.view";
import Footer from "../../Footer/View/Footer.view";
// import Banner from "../../Banner/View/Banner.view";
import "./Style/DrinkMenuView.css";

export interface IMenuViewProps {
    menu: DrinkMenuModel[];
    loading: ILoading;
    error: IError;
}

const DrinkMenuView: React.FC<IMenuViewProps> = (props) => {
    const { t } = useTranslation();

    return (
        <>
            {/* <Banner isNoContent /> */}
            <div className="drink-menu-wrapper">
                <h1 className="main-title">{t("Itallap")}</h1>
                <div className="menu-content">
                    {props.loading ? (
                        <Loading />
                    ) : (
                        props.menu.map((drinkMenu, drinkMenuIndex) => {
                            return (
                                <React.Fragment key={`category-${drinkMenuIndex}`}>
                                    <h2 className="sub-title">{drinkMenu.category}</h2>
                                    {!drinkMenu.has_subcategory && (
                                        <div className="menu-item">
                                            <div className="menu-item__left" />
                                            <div className="menu-item__right">
                                                {drinkMenu.units.map((unit, unitIndex) => {
                                                    return (
                                                        <div
                                                            className="price"
                                                            key={`unit-${unitIndex}`}
                                                        >
                                                            {unit}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                    {drinkMenu.items.map((menuItem, itemIndex) => {
                                        if ("subcategory" in menuItem) {
                                            return (
                                                <React.Fragment key={`${itemIndex}-sub-item`}>
                                                    <h3 className="sub-title-2">
                                                        {menuItem.subcategory}
                                                    </h3>
                                                    <div className="menu-item">
                                                        <div className="menu-item__left" />
                                                        <div className="menu-item__right">
                                                            {drinkMenu.units.map(
                                                                (unit, priceIndex) => {
                                                                    return (
                                                                        <div
                                                                            className="price"
                                                                            key={`${priceIndex}-price`}
                                                                        >
                                                                            {unit}
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    </div>
                                                    {menuItem.items.map((item, subItemIndex) => {
                                                        return (
                                                            <div
                                                                className="menu-item"
                                                                key={`${menuItem.subcategory}-${subItemIndex}`}
                                                            >
                                                                <div className="menu-item__left">
                                                                    {item.name ||
                                                                        t("missingFoodName")}
                                                                    <p className="text">
                                                                        {item.desc1}
                                                                    </p>
                                                                    <p className="text">
                                                                        {item.desc2}
                                                                    </p>
                                                                </div>
                                                                <div className="menu-item__right">
                                                                    {item.prices.map(
                                                                        (price, priceIndex) => {
                                                                            return (
                                                                                <div
                                                                                    className="price"
                                                                                    key={`${priceIndex}-price-index`}
                                                                                >
                                                                                    {price
                                                                                        ? price +
                                                                                          " Ft"
                                                                                        : null}
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </React.Fragment>
                                            );
                                        } else
                                            return (
                                                <div
                                                    className="menu-item"
                                                    key={`menu-item--${itemIndex}`}
                                                >
                                                    <div className="menu-item__left">
                                                        {menuItem.name || t("missingFoodName")}
                                                        <p className="text">{menuItem.desc1}</p>
                                                        <p className="text">{menuItem.desc2}</p>
                                                    </div>
                                                    <div className="menu-item__right">
                                                        {menuItem.prices.map(
                                                            (price, priceIndex) => {
                                                                return (
                                                                    <span
                                                                        className="price"
                                                                        key={`${priceIndex}-p-index`}
                                                                    >
                                                                        {price
                                                                            ? price + " Ft"
                                                                            : null}
                                                                    </span>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                    })}
                                </React.Fragment>
                            );
                        })
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default DrinkMenuView;
