import { IDynamicObject, setNumber, setString } from "xa-generics";

export class ItemModel {
    constructor(data?: IDynamicObject<any>) {
        if (!data) return;

        this.desc1 = setString(data.desc1);
        this.desc2 = setString(data.desc2);
        this.name = setString(data.name);

        this.description = setString(data.description);
        this.price = setString(data.price);

        if (data.prices instanceof Array) {
            for (let price of data.prices) {
                this.prices.push(setNumber(price));
            }
        }
    }

    desc1: string = "";
    desc2: string = "";
    name: string = "";
    prices: number[] = [];
    //These exist only on the 'menu', not drink menu
    description: string = "";
    price: string = "";
}
