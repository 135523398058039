import { IDynamicObject, setString } from "xa-generics";
import { ItemModel } from "./Item.model";

export class SubItemModel {
    constructor(data?: IDynamicObject<any>) {
        if (!data) return;
        this.subcategory = setString(data.subcategory);

        if (data.items instanceof Array) {
            for (let item of data.items) {
                this.items.push(new ItemModel(item));
            }
        }
    }

    subcategory: string = "";
    items: ItemModel[] = [];
}
