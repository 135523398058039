import { AxiosPromise, AxiosResponse } from "axios";
import { axios, IDynamicObject } from "xa-generics";
import { DrinkMenuModel } from "../Model/DrinkMenu.model";
import { MenuModel } from "../Model/Menu.model";

export abstract class MenuDAO {
    private static readonly foods: string = "/api/carte";
    private static readonly drinks: string = "/api/drink";

    public static async getDrinkMenu(): Promise<DrinkMenuModel[]> {
        const request: AxiosPromise<IDynamicObject<any>[]> = axios
            .getInstance()
            .get(`${this.drinks}`);
        const response: AxiosResponse<IDynamicObject<any>[]> = await request;
        return response.data.map((drinkData) => new DrinkMenuModel(drinkData));
    }

    public static async getMenu(): Promise<MenuModel[]> {
        const request: AxiosPromise<IDynamicObject<any>[]> = axios
            .getInstance()
            .get(`${this.foods}`);
        const response: AxiosResponse<IDynamicObject<any>[]> = await request;
        return response.data.map((menu) => new MenuModel(menu));
    }
}
