import { IReservationForm } from "./../Interface/IReservationForm.interface";

export const ReservationForm: IReservationForm = {
    name: {
        id: "name",
        value: "",
        required: true,
        placeholder: "Kovács Lajos"
    },
    tel: {
        id: "tel",
        value: "",
        required: true,
        placeholder: "+36 70 101 1010"
    },
    email: {
        id: "email",
        value: "",
        required: true,
        placeholder: "pelda@gmail.com"
    },
    comment: {
        id: "comment",
        value: ""
    },
    date: {
        id: "date",
        value: "",
        required: true
    },
    time: {
        id: "time",
        value: "",
        required: true
    },
    personCount: {
        id: "personCount",
        value: "",
        required: true,
        placeholder: "2"
    }
};
