import * as React from "react";
import * as ReactDOM from "react-dom";
import * as ServiceWorker from "./serviceWorker";
import Router from "./Router";
import Cookies from "js-cookie";
import LangConfig from "./Lang/Lang";
import DefaultAxios from "axios";
import { AxiosResponse, AxiosError } from "axios";
import { axios, IDynamicObject, PageWrapperPaddings } from "xa-generics";
import { BrowserRouter } from "react-router-dom";
import "./Assets/icons/font/flaticon.css";
import "./Style/Fonts.css";
import "./Style/Index.css";

const lang: string | undefined = Cookies.get("lang");

LangConfig(lang);

DefaultAxios.get("/api/rest")
    .then((response: AxiosResponse<IDynamicObject>) => response.data)
    .then((ENV_KEYS: IDynamicObject) => {
        axios.initAxios(ENV_KEYS.REST_API_URL, undefined, (lang as "hu") || "hu");

        PageWrapperPaddings.set("8rem", "0rem");

        const AppRoot = (
            <BrowserRouter>
                <Router />
            </BrowserRouter>
        );

        ReactDOM.render(AppRoot, document.getElementById("root"));

        // My note about ServiceWorker. If you register it, the updates won't apply to the page
        // unless they open it once, close the page, and open it again.

        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://bit.ly/CRA-PWA
        ServiceWorker.unregister();
    })
    .catch((error: AxiosError) => {
        ReactDOM.render(
            <div>
                <h2>We're sorry but something has gone wrong.</h2>
                <p>Error:</p>
                <p>{error.message}</p>
            </div>,
            document.getElementById("root")
        );
    });
