import { axios, IDynamicObject } from "xa-generics";
import { AxiosPromise, AxiosResponse } from "axios";
import { WeeklyMenuModel } from "../Model/WeeklyMenu.model";

export abstract class WeeklyMenuDAO {
    private static readonly base: string = "/api/menu";

    public static async getWeeklyMenu(): Promise<WeeklyMenuModel> {
        const request: AxiosPromise<IDynamicObject> = axios.getInstance().get(`${this.base}`);
        const response: AxiosResponse<IDynamicObject> = await request;
        return new WeeklyMenuModel(response.data);
    }
}
