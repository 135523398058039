import React from "react";
import Banner from "../../Banner/View/Banner.view";
import Footer from "../../Footer/View/Footer.view";
import AboutUs from "../../AboutUs/View/About.view";
import WeeklyMenu from "../../WeeklyMenu/Controller/WeeklyMenu.controller";
import Reservation from "../../Reservation/Controller/Reservation.controller";

export interface IMainPageProps {}

const MainPage: React.FC<IMainPageProps> = (props) => {
    return (
        <Banner>
            <AboutUs />
            <WeeklyMenu />
            <Reservation />
            <Footer />
        </Banner>
    );
};

export default MainPage;
