import React, { useState, useEffect } from "react";
import { ILoading, IError } from "xa-generics";
import { DrinkMenuModel } from "../Model/DrinkMenu.model";
import { MenuDAO } from "../DAO/Menu.dao";
import DrinkMenuView from "../View/DrinkMenu.view";

export interface IDrinkMenuProps {}

const DrinkMenu: React.FC<IDrinkMenuProps> = (props) => {
    const [loading, setLoading] = useState<ILoading>(true);
    const [menu, setMenu] = useState<DrinkMenuModel[]>([]);
    const [error, setError] = useState<IError>(null);

    const loadMenu = (): void => {
        setLoading(
            MenuDAO.getDrinkMenu()
                .then((drinks: DrinkMenuModel[]) => setMenu(drinks))
                .catch((error: IError) => setError(error))
                .finally(() => setLoading(false))
        );
    };
    useEffect(loadMenu, []);

    return <DrinkMenuView loading={loading} menu={menu} error={error} />;
};

export default DrinkMenu;
