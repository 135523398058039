import { ModelConstructor, IDynamicObject, setString } from "xa-generics";

export class WeeklyMenuModel {
    constructor(data?: IDynamicObject<any>) {
        if (!data) return;

        this.menuPrice = setString(data.price as string);
        this.weekDays = data.menuItems.map((item: IDynamicObject) => new DayModel(item));
    }

    menuPrice: string = "";
    weekDays: DayModel[] = [];
}

export class DayModel {
    constructor(data?: IDynamicObject) {
        if (!data) return;
        ModelConstructor(data, this);
    }

    soup: string = "";
    mainCourse: string = "";
    mainCourseB: string = "";
    dessert: string = "";
    alternateDishPrice: number = 0;
    alternateDishName: string = "";
    alternateDish1: string = "";
    alternateDish2: string = "";
    alternateDish3: string = "";
    day: string = "";
    date: string = "";
}
