import React from "react";
import { RouteComponentProps } from "react-router";
import { PageWrapper } from "xa-generics";
import NavigationBar from "../../Navigation/View/Navigation.view";

export interface ILayoutProps extends RouteComponentProps {}

const Layout: React.FC<ILayoutProps> = (props) => {
    const classes: string[] = ["main-page"];
    if (props.location.pathname === "/helyszinek" || props.location.pathname === "/kitchen") {
        classes.push("no-background-wrapper");
    }
    return (
        <div className={classes.join(" ")}>
            <NavigationBar {...props} />
            <PageWrapper>{props.children}</PageWrapper>
        </div>
    );
};

export default Layout;
