import React, { useState, useEffect } from "react";
import { ILoading, IError } from "xa-generics";
import { MenuModel } from "../Model/Menu.model";
import { MenuDAO } from "../DAO/Menu.dao";
import MenuView from "../View/Menu.view";

export interface IDrinkMenuProps {}

const MenuController: React.FC<IDrinkMenuProps> = (props) => {
    const [loading, setLoading] = useState<ILoading>(true);
    const [menu, setMenu] = useState<MenuModel[]>([]);
    const [error, setError] = useState<IError>(null);

    const loadMenu = (): void => {
        setLoading(
            MenuDAO.getMenu()
                .then((menuList: MenuModel[]) => setMenu(menuList))
                .catch((error: IError) => setError(error))
                .finally(() => setLoading(false))
        );
    };
    useEffect(loadMenu, []);

    return <MenuView loading={loading} menu={menu} error={error} />;
};

export default MenuController;
