import React, { useState } from "react";
import { changeHandler, IError } from "xa-generics";
import { IReservationForm } from "../Interface/IReservationForm.interface";
import { ReservationForm } from "../Static/Reservation.form";
import { useTranslation } from "react-i18next";
import { ReservationDAO } from "../DAO/Reservation.dao";
import { cloneDeep } from "lodash";
import ReservationView from "../View/Reservation.view";
import qs from "qs";

export interface IReservationProps {}

const Reservation: React.FC<IReservationProps> = (props) => {
    const [form, setForm] = useState<IReservationForm>(cloneDeep(ReservationForm));
    const { t } = useTranslation();

    const onSubmit = (e: React.FormEvent): void => {
        e.preventDefault();

        const data = qs.stringify({
            name: form.name.value,
            tel: form.tel.value,
            email: form.email.value,
            comment: form.comment.value,
            date: form.date.value,
            time: form.time.value,
            personCount: form.personCount.value
        });

        ReservationDAO.reserveTable(data)
            .then(() => {
                setForm(cloneDeep(ReservationForm));
                alert(t("alertMessage"));
            })
            .catch((error: IError) => {
                alert(error);
            });
    };

    return (
        <ReservationView
            onSubmit={onSubmit}
            onChange={(args) => setForm(changeHandler(form, args.fieldName, args.value))}
            form={form}
        />
    );
};

export default Reservation;
