import React, { useEffect } from "react";
import { withRouter, Switch, Route, RouteComponentProps } from "react-router-dom";
// import Locations from "./Components/Locations/View/Locations";
import MenuController from "./Components/Menu/Controller/Menu.controller";
import DrinkMenu from "./Components/Menu/Controller/DrinkMenu.controller";
import MainPage from "./Components/MainPage/View/MainPage.view";
import Layout from "./Components/Layout/View/Layout.view";

export interface IRouterProps extends RouteComponentProps {}

const Router: React.FC<IRouterProps> = (props) => {
    const handleRouteChange = () => {
        window.scrollTo(0, 0);
        if (props.location.pathname !== "/" || !props.location.hash) return;

        setTimeout(() => {
            let hash = props.location.hash.substring(1);
            const element = document.getElementById(hash);
            if (!element) return;
            element.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest"
            });
        }, 800);
    };

    useEffect(handleRouteChange, [props.location.pathname]);

    return (
        <Layout {...props}>
            <Switch>
                {/* <Route path="/rendezvenyek" exact component={Locations} />*/}
                <Route path="/etlap" exact component={MenuController} />
                <Route path="/itallap" exact component={DrinkMenu} />
                <Route path="*" component={MainPage} />
            </Switch>
        </Layout>
    );
};

export default withRouter(Router);
